import { t } from "@/i18n-js/instance";
import { AutoCompleteInput } from "@/react/components/SettingsApp/Workflows/components/Table/HistoryWorkflowsTable/HistoryFilters/CommunityMemberFilter/AutoCompleteInput";
import { useFilterContext } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/FilterContext";
import { useCommunityMember } from "@circle-react/hooks/useCommunityMember";
import { Filter } from "@circle-react-shared/Filter";

export interface CommunityMemberFilterProps {
  isInitialOpen?: boolean;
  name: string;
}

export const CommunityMemberFilter = ({
  isInitialOpen,
  name,
}: CommunityMemberFilterProps) => {
  const { filters, setFilters } = useFilterContext();
  const memberId = filters[name];
  const {
    communityMemberQuery: { data: communityMember },
  } = useCommunityMember({ memberId });

  const handleApply = (value: string) => {
    setFilters({
      ...filters,
      [name]: value?.toString(),
    });
  };

  const getSelectedMember = () => {
    if (!communityMember) {
      return;
    }
    return communityMember.name;
  };

  return (
    <Filter
      chip={t(`search_v2.filters.${name}`)}
      title={t(`search_v2.filters.${name}`)}
      selectedText={memberId}
      renderSelectedText={getSelectedMember}
      isInitialOpen={isInitialOpen}
      shouldShowRemoveButton
      onApply={handleApply}
    >
      <AutoCompleteInput name={name} defaultValue={communityMember} />
    </Filter>
  );
};
