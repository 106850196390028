import { t } from "@/i18n-js/instance";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-shared/uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

interface NewConversationBlockProps {
  createMessage: (data: { body: object; pause_ai_minutes?: number }) => void;
}

export const NewConversationBlock = ({
  createMessage,
}: NewConversationBlockProps) => {
  const { currentBotProfile } = useCommunityInboxStore();

  const formatMessageBody = (text: string) => ({
    type: "doc",
    content: [
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: text,
          },
        ],
      },
    ],
  });

  return (
    <div className="mx-2 mb-4 flex flex-col items-start">
      {currentBotProfile?.avatar_url && (
        <img
          src={currentBotProfile?.avatar_url}
          alt={currentBotProfile?.name}
          className="mb-2 h-12 w-12 rounded-full"
          loading="lazy"
        />
      )}

      {!currentBotProfile?.avatar_url && (
        <Icon type="48-ai-bot" size={48} className="mb-2 !h-[3rem] !w-[3rem]" />
      )}

      <Typography.TitleSm as="h2" weight="semibold">
        {t("messaging.start_conversation")}
      </Typography.TitleSm>
      <div className="mb-3">
        <Typography.BodySm color="text-default">
          {t("messaging.bot_start_conversation", {
            name: currentBotProfile?.name,
          })}
        </Typography.BodySm>
      </div>

      {currentBotProfile?.suggested_questions &&
        currentBotProfile.suggested_questions.length > 0 && (
          <div className="flex flex-wrap items-center gap-2">
            {currentBotProfile.suggested_questions.map((question: string) => (
              <Button
                key={question}
                variant="tertiary"
                onClick={() =>
                  createMessage({
                    body: {
                      body: formatMessageBody(question),
                      attachments: [],
                      localAttachments: [],
                      localSgidToObjectMap: {},
                    },
                  })
                }
                className="border-primary !rounded-full border !px-3 !py-1.5 text-sm !leading-none"
              >
                {question}
              </Button>
            ))}
          </div>
        )}
    </div>
  );
};
