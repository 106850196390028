import { t } from "@/i18n-js/instance";
import { useAccessGroups } from "@circle-react/hooks/accessRegistry/useAccessGroups";
import { Button } from "@circle-react-uikit/Button";
import { Loader } from "@circle-react-uikit/Loader";
import { Tab } from "@circle-react-uikit/TabV2";
import { FormItems } from "./FormItems";
import { Preview } from "./Preview";
import { useMigrationForm } from "./hooks/useMigrationForm";

const i18nBase = "settings.access_groups.paywall_migration.modal";

export const FormContent = ({ closeModals }: { closeModals: () => void }) => {
  const { data: accessGroups, isLoading: isLoadingAccessGroups } =
    useAccessGroups();

  const {
    handleTabChange,
    selectedTabIndex,
    isReviewTab,
    handleFormNavigation,
  } = useMigrationForm({ closeModals });

  if (isLoadingAccessGroups) {
    return <Loader />;
  }

  return (
    <>
      <div className="h-[500px] overflow-y-auto">
        <Tab.Group
          className="flex h-full flex-col"
          onChange={handleTabChange}
          selectedIndex={selectedTabIndex}
        >
          <div className="px-8">
            <Tab.List>
              <Tab>{t([i18nBase, "tabs.select"])}</Tab>
              <Tab>{t([i18nBase, "tabs.review"])}</Tab>
            </Tab.List>
          </div>
          <div className="border-primary w-full border-t" />
          <div className="flex-1 overflow-y-auto px-8">
            <Tab.Panels>
              <Tab.Panel>
                <FormItems accessGroups={accessGroups} />
              </Tab.Panel>
              <Tab.Panel>
                <Preview />
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
      <div className="border-primary w-full border-t" />

      <div className="mb-2 mt-5 flex justify-end px-8">
        <Button
          type="button"
          variant="primary"
          className="!py-2"
          onClick={handleFormNavigation}
        >
          {isReviewTab
            ? t([i18nBase, "form.buttons.confirm"])
            : t([i18nBase, "form.buttons.next"])}
        </Button>
      </div>
    </>
  );
};
