import { flatten, omit, values } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  reactQueryGet,
  reactQueryPost,
  reactQueryPut,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import type { ProfileFieldBaseFilter } from "@circle-react-shared/MemberFilters/types";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

const INDEX_QUERY_KEY = ["internal_api", "community_bot", "agents"];

interface AgentResponse {
  records: Array<AgentProp>;
  count: number;
  page: number;
  per_page: number;
}

export interface AgentProp {
  id: number;
  enabled: boolean;
  name: string;
  avatar_url: string;
  updated_at: string;
  created_at: string;
  bio: object;
  welcome_message: object;
  answer_type: "ai-generated" | "custom";
  tone: "normal" | "friendly" | "short-and-sweet" | "descriptive";
  purpose: string;
  use_community_knowledge: boolean;
  use_custom_snippets: boolean;
  use_platform_support: boolean;
  use_all_kb_posts: boolean;
  posts_count: number;
  snippets_count: number;
  members_filters?: Array<BaseFilter | ProfileFieldBaseFilter>;
  send_welcome_message: boolean;
  pause_ai_keywords: Array<string>;
  pause_ai_handoff_message: any;
}

export interface UpdateAgentRequest {
  id?: number;
  avatar?: string;
  enabled?: boolean;
  bio?: object;
  welcome_message?: object;
  members_filters?: Array<object>;
  name?: string;
  answer_type?: "ai-generated" | "custom";
  tone?: "normal" | "friendly" | "short-and-sweet" | "descriptive";
  purpose?: string;
  send_welcome_message?: boolean;
  pause_ai_keywords?: Array<string>;
  pause_ai_handoff_message?: any;
}

export const useIndexAgents = ({ currentPage }: { currentPage: number }) =>
  useQuery<AgentResponse>({
    queryKey: INDEX_QUERY_KEY,
    queryFn: () =>
      reactQueryGet(
        internalApi.communityBot.agents.index({
          params: {
            page: currentPage,
            per_page: 10,
          },
        }),
      ),
  });

export const useShowAgent = ({ id }: { id: number }) =>
  useQuery<AgentProp>({
    queryKey: [...INDEX_QUERY_KEY, id],
    queryFn: () => reactQueryGet(internalApi.communityBot.agents.show({ id })),
  });

export const useCreateAgent = () => {
  const { success } = useToast();

  return useMutation<AgentProp, Error, void>(
    async () => {
      const response: AgentProp = await reactQueryPost(
        internalApi.communityBot.agents.create(),
      );
      return response;
    },
    {
      onSuccess: () => {
        success(t("changes_saved"));
      },
    },
  );
};

export const useUpdateAgent = ({ id }: { id: number }) => {
  const queryClient = useQueryClient();
  const { success, error } = useToast();
  const { refetch: refetchPundit } = usePunditUserContext();

  return useMutation<AgentProp, Error, UpdateAgentRequest>(
    (data: UpdateAgentRequest) => {
      const isEnabledUpdate =
        Object.keys(data).length === 1 && "enabled" in data;

      return reactQueryPut(internalApi.communityBot.agents.update({ id }), {
        community_bot_profile: isEnabledUpdate
          ? { enabled: data.enabled }
          : omit(data, "enabled"),
      });
    },
    {
      onSuccess: data => {
        void queryClient.invalidateQueries([...INDEX_QUERY_KEY, data.id]);
        void queryClient.invalidateQueries([...INDEX_QUERY_KEY]);
        success(t("changes_saved"));
      },
      onError: async (exception: any) => {
        await refetchPundit();
        if (exception?.body?.error_details) {
          const details = values(
            omit(exception.body.error_details, "message", "success"),
          );
          error(flatten(details).join(", "));
        } else {
          error(t("request_failure_message"));
        }
      },
    },
  );
};
