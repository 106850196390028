import { t } from "@/i18n-js/instance";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import { useFilterContext } from "@circle-react-shared/Filter/Filter";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";

interface AutoCompleteInputProps {
  name?: string;
  defaultValue?: any;
}

export const AutoCompleteInput = ({
  name = "member-filter",
}: AutoCompleteInputProps) => {
  const { selectedText, onApply } = useFilterContext();
  const handleApply = (
    submitData: Record<string, any>,
    { reset }: { reset: () => void },
  ) => {
    onApply(submitData[name]);
    reset();
  };
  const primaryButtonVariant = useButtonVariant();

  return (
    <Form
      defaultValues={{ [name]: +(selectedText || 0) }}
      onSubmit={handleApply}
    >
      <div className="flex flex-col gap-y-4 p-4">
        <Form.CommunityMemberSelect
          name={name}
          includeInactive
          direction="down"
          autoFocus
        />
        <Button variant={primaryButtonVariant} type="submit" full>
          {t("filters.apply")}
        </Button>
      </div>
    </Form>
  );
};
