import { useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { TextSelect } from "@/react/components/shared/TextSelect";
import type { AccessGroupType } from "@/react/types/AccessGroup";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { AccessGroupCard } from "@circle-react-shared/AccessRegistry/AccessGroupCard";
import { AccessGroupFormActions } from "./AccessGroupFormActions";
import { FORM_FIELD_KEYS } from "./constants";

const i18nBase = "settings.access_groups.paywall_migration.modal.form";

interface AccessGroupsChooserProps {
  accessGroups: AccessGroupType[];
}

export const AccessGroupsChooser = ({
  accessGroups,
}: AccessGroupsChooserProps) => {
  const { remove, append } = useFieldArray({
    name: FORM_FIELD_KEYS.access_groups,
  });

  const { watch } = useFormContext();
  const selectedAccessGroups: Array<AccessGroupType> = watch(
    FORM_FIELD_KEYS.access_groups,
  );

  const selectableAccessGroups = () =>
    accessGroups.filter(
      accessGroup =>
        !selectedAccessGroups.some(
          selectedGroup => selectedGroup.id === accessGroup.id,
        ),
    );

  const onChange = (value: string) => {
    const accessGroup = accessGroups.find(
      accessGroup => `${accessGroup.id}` === value,
    );
    if (accessGroup) {
      append(accessGroup);
    }
  };

  const options = useMemo(
    () =>
      selectableAccessGroups().map(accessGroup => ({
        value: `${accessGroup.id}`,
        label: accessGroup.name,
      })),
    [selectableAccessGroups],
  );

  return (
    <>
      <TextSelect
        placeholder={t([i18nBase, "chooser", "placeholder"])}
        emptyMessage={t([i18nBase, "chooser", "no_results"])}
        options={options}
        onChange={onChange}
        value=""
      />
      <div className="overflow-y-auto">
        {selectedAccessGroups.map((accessGroup, index, array) => (
          <AccessGroupCard
            className={classNames({
              "rounded-none border-t-0": index > 0 && index < array.length - 1,
              "rounded-b-none": index === 0 && array.length > 1,
              "rounded-t-none border-t-0":
                index === array.length - 1 && array.length > 1,
            })}
            key={accessGroup.id}
            title={accessGroup.name}
            actions={
              <AccessGroupFormActions
                accessGroupIndex={index}
                removeAccessGroup={remove}
              />
            }
            membersCount={parseInt(accessGroup.total_members ?? "0", 10)}
            spacesCount={parseInt(accessGroup.total_spaces ?? "0", 10)}
          />
        ))}
      </div>
    </>
  );
};
