import { t } from "@/i18n-js/instance";
import { useFilterContext } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/FilterContext";
import { Filter } from "@circle-react-shared/Filter";

export interface DateFilterProps {
  isInitialOpen?: boolean;
  name?: string;
}

const DATE_FILTER_ITEMS = {
  all: "all",
  today: "today",
  yesterday: "yesterday",
  week: "week",
  month: "month",
  year: "year",
} as const;

const localeScope = "search_v2.filters.date_ranges";

export const DateFilter = ({
  isInitialOpen,
  name = "name",
}: DateFilterProps) => {
  const { filters, setFilters } = useFilterContext();
  const setActiveItem = (value: string) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };
  return (
    <Filter
      chip={t(`search_v2.filters.${name}`)}
      title={t(`search_v2.filters.${name}`)}
      selectedText={filters?.[name] ? t([localeScope, filters?.[name]]) : null}
      isInitialOpen={isInitialOpen}
      onApply={setActiveItem}
    >
      <div className="p-2">
        {Object.values(DATE_FILTER_ITEMS).map((item: string) => (
          <button
            type="button"
            key={item}
            aria-label={t([localeScope, item])}
            onClick={() => setActiveItem(item)}
            className="hover:bg-tertiary text-xs-plus group block w-full rounded-lg px-2 py-[10px] text-left font-medium transition-colors duration-75"
          >
            <div className="flex items-center gap-x-2">
              <span>{t([localeScope, item])}</span>
            </div>
          </button>
        ))}
      </div>
    </Filter>
  );
};
