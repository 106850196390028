import { useFormContext } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import type { MenuBarProps } from "@circle-react-shared/uikit/TipTap/MenuBar";
import { MenuIconButton } from "@circle-react-shared/uikit/TipTap/MenuBar/MenuIconButton";
import { isMentionsOrEmojiPopoverOpen } from "@circle-react-shared/uikit/TipTap/utilities/helper";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";
import { SendAndPauseAITooltipContent } from "./SendAndPauseAITooltipContent";

interface DMsMenuBarProps extends MenuBarProps {
  isDms: boolean;
}

export const MenuBar = ({
  isSubmitting,
  isUploading,
  editorHasNoContent,
  handleSubmit,
  isDms = true,
}: DMsMenuBarProps) => {
  const { setValue } = useFormContext();
  const isLoading = isSubmitting || isUploading;
  const onSubmit = (minutes?: number) => {
    if (minutes) setValue("pause_ai_minutes", minutes);
    handleSubmit();
  };
  const isPopoverOpen = isMentionsOrEmojiPopoverOpen();

  const onSubmitHandler = () => {
    if (isDms) {
      onSubmit();
    } else {
      onSubmit(5);
    }
  };

  useHotkeys(
    "enter",
    onSubmitHandler,
    {
      enabled: !isDms && !isPopoverOpen,
      enableOnContentEditable: true,
    },
    [isDms, isPopoverOpen],
  );
  return (
    <div className="flex items-center justify-center gap-2">
      <TippyV2
        placement="top"
        interactive={false}
        arrow={isDms}
        content={
          isDms ? (
            t("community_inbox.send_message")
          ) : (
            <SendAndPauseAITooltipContent />
          )
        }
      >
        <MenuIconButton
          type="submit"
          onClick={onSubmitHandler}
          disabled={isSubmitting || editorHasNoContent() || isUploading}
          id="tiptap-send"
          aria-label={t("community_inbox.send_message")}
          icon={isLoading ? "loader" : "28-message-send"}
          size={isLoading ? 20 : 28}
          isLight={!editorHasNoContent()}
          shouldAnimateSpin={isLoading}
          isSendButton
        />
      </TippyV2>
      {!isDms && (
        <Dropdown
          className="py-2"
          direction="bottom-end"
          button={
            <Dropdown.MenuButton id="send-pause-ai">
              <Icon
                type="16-chevron-down"
                className="text-gray-400"
                size={16}
              />
            </Dropdown.MenuButton>
          }
        >
          <div className="mb-1.5">
            <Typography.BodyXs weight="font-bold" color="text-light">
              <span className="uppercase">
                {t("community_bot.pause_ai.send_and_pause_ai")}
              </span>
            </Typography.BodyXs>
          </div>
          <Dropdown.Item onClick={() => onSubmit(5)}>
            <div className="hover:bg-tertiary flex cursor-pointer items-center gap-2 rounded-md px-1 py-2">
              <Icon type="16-pause-timer" size={16} />
              <Typography.BodySm weight="font-medium">
                {t("community_bot.pause_ai.five_minutes")}
              </Typography.BodySm>
            </div>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => onSubmit(15)}>
            <div className="hover:bg-tertiary flex cursor-pointer items-center gap-2 rounded-md px-1 py-2">
              <Icon type="16-pause-timer" size={16} />
              <Typography.BodySm weight="font-medium">
                {t("community_bot.pause_ai.fifteen_minutes")}
              </Typography.BodySm>
            </div>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => onSubmit(60)}>
            <div className="hover:bg-tertiary flex cursor-pointer items-center gap-2 rounded-md px-1 py-2">
              <Icon type="16-pause-timer" size={16} />
              <Typography.BodySm weight="font-medium">
                {t("community_bot.pause_ai.one_hour")}
              </Typography.BodySm>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => onSubmit(525600)} //60x24x365 minutes
          >
            <div className="hover:bg-tertiary flex cursor-pointer items-center gap-2 rounded-md px-1 py-2">
              <Icon type="16-pause-timer" size={16} />
              <Typography.BodySm weight="font-medium">
                {t("community_bot.pause_ai.indefinitely")}
              </Typography.BodySm>
            </div>
          </Dropdown.Item>
        </Dropdown>
      )}
    </div>
  );
};
