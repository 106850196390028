import { useSpaceAccessManagementContext } from "@circle-react/components/Spaces/SpaceAccessManagement/SpaceAccessManagementContext";
import type { SpaceGroupOption } from "../../../SpaceAccessManagementContext";

interface UseParentItemProp {
  spaceGroup: SpaceGroupOption;
  onAddParent?: (action: () => void, object: SpaceGroupOption) => void;
  onRemoveParent?: (action: () => void, object: SpaceGroupOption) => void;
}

export const useParentItem = ({
  spaceGroup,
  onAddParent = action => {
    action();
  },
  onRemoveParent = action => {
    action();
  },
}: UseParentItemProp) => {
  const { id, name, spaces } = spaceGroup;
  const {
    isSpaceGroupSelected,
    isSpaceSelected,
    addSpaceGroups,
    addSpaces,
    getIsFromSearch,
    removeSpaceGroup,
    removeSpaces,
    notRemovableParentItemTooltip: isNotRemovableTooltip,
    isSpaceGroupRemovable,
    isSpaceRemovable,
  } = useSpaceAccessManagementContext();

  const isFromSearch =
    getIsFromSearch(name) || spaces.some(space => getIsFromSearch(space.name));
  const spaceIds = spaces.map(space => space.id);

  const moveAllSpacesWithinSpaceGroup = () => {
    addSpaceGroups(id);
    const spacesToAdd = spaceIds.filter(spaceId => !isSpaceSelected(spaceId));
    addSpaces(spacesToAdd);
  };
  const onAddClick = () =>
    onAddParent(moveAllSpacesWithinSpaceGroup, spaceGroup);
  const spacesAlreadySelected = (spaces || []).filter(space =>
    isSpaceSelected(space.id),
  );
  const isChecked = isSpaceGroupSelected(id);
  const isAllSpacesSelected =
    isChecked && spacesAlreadySelected.length === spaces.length;
  const selectedAndRemovableSpaces = spacesAlreadySelected.filter(space =>
    isSpaceRemovable(space.id),
  );
  const areAllSpacesSelectedAndRemovable =
    isChecked && selectedAndRemovableSpaces.length === spaces.length;

  const removeAllSpacesWithinSpaceGroup = () => {
    removeSpaceGroup(id);
    removeSpaces(spaceIds);
  };

  const isRemovable = isSpaceGroupRemovable(id);
  const onRemoveClick = () => {
    onRemoveParent(removeAllSpacesWithinSpaceGroup, spaceGroup);
  };

  return {
    isChecked,
    isFromSearch,
    isAllSpacesSelected,
    areAllSpacesSelectedAndRemovable,
    spacesAlreadySelected,
    selectedAndRemovableSpaces,
    onAddClick,
    isRemovable,
    isNotRemovableTooltip,
    onRemoveClick,
  };
};
