import { useMemo } from "react";
import { useAccessGroups } from "@circle-react/hooks/accessRegistry/useAccessGroups";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";

export const useAccessGroupNameMap = (filterParam?: BaseFilter[]) => {
  const accessGroupIds = filterParam?.map(({ value }) => String(value));

  const { data: accessGroups } = useAccessGroups({
    params: {
      ids: accessGroupIds,
    },
    enabled: !!accessGroupIds?.length,
  });

  return useMemo(
    () =>
      accessGroups?.reduce((accumulator: any, accessGroup: AccessGroupType) => {
        accumulator[String(accessGroup.id)] = accessGroup.name;
        return accumulator;
      }, {}),
    [accessGroups],
  );
};
