import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { FormItem } from "@/react/components/SettingsApp/General/FormItem";
import { Button } from "@/react/components/shared/uikit/Button";
import type { AgentProp } from "@circle-react/components/SettingsApp/CommunityBot/agentsQueries";
import { Form } from "@circle-react-shared/uikit/Form";
import { FormTipTapEditor } from "@circle-react-shared/uikit/Form/FormTipTapEditor";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Accordion } from "@circle-react-uikit/Accordion";
import { TagsInput } from "../shared/TagsInput";
import { DEFAULT_PAUSE_AI_HANDOFF_MESSAGE } from "./constants";
import { defaultSuggestedPauseAiKeywords } from "./defaultSuggestedPauseAiKeywords";

interface FormDataProps
  extends Pick<AgentProp, "pause_ai_keywords" | "pause_ai_handoff_message"> {}

export const PauseAISetting = ({
  formDefaultValues,
}: {
  formDefaultValues: FormDataProps;
}) => {
  const { setValue, getValues } = useFormContext();
  const pauseAiHandOffMessage = useWatch({ name: "pause_ai_handoff_message" });
  const pauseAiKeywords = useWatch({ name: "pause_ai_keywords" });
  const getFilteredSuggestedKeywords = () => {
    if (!pauseAiKeywords) return defaultSuggestedPauseAiKeywords;

    const normalizedCurrentKeywords = pauseAiKeywords.map((k: string) =>
      k.toLowerCase(),
    );

    return defaultSuggestedPauseAiKeywords.filter(
      keyword => !normalizedCurrentKeywords.includes(keyword.toLowerCase()),
    );
  };

  useEffect(() => {
    if (!pauseAiHandOffMessage) {
      setValue("pause_ai_handoff_message", DEFAULT_PAUSE_AI_HANDOFF_MESSAGE, {
        shouldTouch: true,
      });
    }
    if (!pauseAiKeywords) {
      setValue("pause_ai_keywords", [], {
        shouldTouch: true,
      });
    }
  }, [setValue, pauseAiHandOffMessage, pauseAiKeywords]);

  return (
    <div className="border-primary border-t px-6 py-5">
      <Accordion.Item
        title={t(
          "community_bot.bot_builder.behavior_page.pause_ai_setting.title",
        )}
      >
        <Typography.LabelSm color="text-light">
          {t(
            "community_bot.bot_builder.behavior_page.pause_ai_setting.description",
          )}
        </Typography.LabelSm>
        <FormItem
          name="pause_ai_keywords"
          translationRoot="community_bot.bot_builder.behavior_page.pause_ai_setting"
          hidePlaceholder
          hideDescription
        >
          <TagsInput
            name="pause_ai_keywords"
            placeholder={t(
              "community_bot.bot_builder.behavior_page.pause_ai_setting.pause_ai_keywords_placeholder",
            )}
          />
          <div className="flex flex-wrap gap-3">
            {getFilteredSuggestedKeywords().map((keyword: string) => (
              <Button
                key={keyword}
                variant="link"
                className="!px-0 pt-2 underline"
                onClick={e => {
                  e.preventDefault();
                  const currentKeywords = getValues("pause_ai_keywords") || [];
                  setValue("pause_ai_keywords", [...currentKeywords, keyword], {
                    shouldTouch: true,
                  });
                }}
              >
                <Typography.LabelSm color="text-lightest" weight="medium">
                  {keyword}
                </Typography.LabelSm>
              </Button>
            ))}
          </div>
        </FormItem>
        <Form.Label
          fieldId="pause_ai_handoff_message"
          label={t(
            "community_bot.bot_builder.behavior_page.pause_ai_setting.handoff_message",
          )}
          hideBorder
          labelClassName="cursor-text"
        />
        <FormTipTapEditor
          name="pause_ai_handoff_message"
          value={formDefaultValues.pause_ai_handoff_message}
          placeholder={t(
            "community_bot.bot_builder.behavior_page.pause_ai_setting.handoff_message_input_placeholder",
          )}
          showMenuBar={false}
          submitOnEnter={false}
          className="!border-primary !bg-primary focus-within:!border-light !mb-0 !px-4 !text-base"
          editorClassName="min-h-[5rem]"
          type="workflow"
        />
      </Accordion.Item>
    </div>
  );
};
