import { useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Icon } from "@/react/components/shared/Icon";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

const MAX_TAGS = 10;

interface TagsInputProps {
  name: string;
  placeholder: string;
  maxTags?: number;
}

export const TagsInput = ({
  name,
  placeholder,
  maxTags = MAX_TAGS,
}: TagsInputProps) => {
  const [inputValue, setInputValue] = useState("");
  const { setValue, trigger } = useFormContext();
  const tags = useWatch({ name }) || [];
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const { error } = useToast();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      event.preventDefault();
      const trimmedInput = inputValue.trim();

      if (tags.length >= maxTags) {
        error(
          t(
            `community_bot.bot_builder.common.errors.max_${name}_reached` as const,
            { count: maxTags },
          ),
        );
        return;
      }

      const lowerCaseTrimmedInput = trimmedInput.toLowerCase();
      const caseInsensitiveMatch = tags.find(
        (tag: string) => tag.toLowerCase() === lowerCaseTrimmedInput,
      );

      if (caseInsensitiveMatch) {
        error(
          t(
            `community_bot.bot_builder.common.errors.duplicate_${name}` as const,
            {
              tag: caseInsensitiveMatch,
            },
          ),
        );
        return;
      }

      const newTags = [...tags, trimmedInput];
      setValue(name, newTags, { shouldValidate: true, shouldTouch: true });
      setInputValue("");
      void trigger(name);
    } else if (
      event.key === "Backspace" &&
      inputValue === "" &&
      tags.length > 0
    ) {
      event.preventDefault();
      removeTag(tags[tags.length - 1]);
    }
  };

  const removeTag = (tagToRemove: string) => {
    const newTags = tags.filter((tag: string) => tag !== tagToRemove);
    setValue(name, newTags, { shouldValidate: true, shouldTouch: true });
    void trigger(name);
  };

  useEffect(() => {
    const checkFocus = () => {
      setIsFocused(document.activeElement === inputRef.current);
    };

    document.addEventListener("focusin", checkFocus);
    document.addEventListener("focusout", checkFocus);

    return () => {
      document.removeEventListener("focusin", checkFocus);
      document.removeEventListener("focusout", checkFocus);
    };
  }, []);

  return (
    <div
      className={classNames(
        "border-primary focus-within:border-light h-fit-content flex min-h-[3rem] flex-wrap items-center gap-2 rounded-md border py-2 pl-3 pr-2",
        {
          "!border-light": isFocused,
        },
      )}
      aria-label={placeholder}
    >
      {tags.map((tag: string) => (
        <div
          key={tag}
          className="bg-tertiary text-default flex items-center gap-1 rounded-md py-0.5 pl-2 pr-1 text-sm font-medium"
        >
          {tag}
          <button
            type="button"
            onClick={e => {
              e.stopPropagation();
              removeTag(tag);
            }}
            className="text-muted cursor-pointer rounded-full p-0.5 hover:bg-black/10 dark:hover:bg-white/10"
            aria-label={t("community_bot.bot_builder.common.remove_tag", {
              tag,
            })}
          >
            <Icon type="16-close" size={16} />
          </button>
        </div>
      ))}
      {tags.length < maxTags && (
        <input
          ref={inputRef}
          type="text"
          className="grow border-none bg-transparent p-0.5 text-sm placeholder:text-sm placeholder:font-medium focus:shadow-none focus:outline-none focus:ring-0"
          placeholder={
            tags.length < 5
              ? t("community_bot.bot_builder.common.type_and_press_enter", {
                  defaultValue: "Type and press Enter",
                })
              : ""
          }
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      )}
    </div>
  );
};
