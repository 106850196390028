import { useEffect } from "react";
import { flatten } from "lodash";
import { useInfiniteQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const queryKey = ["access_groups", "index"];

interface UseAccessGroupsParams {
  params?: {
    ids?: string[];
  };
  enabled?: boolean;
}

export const useAccessGroups = ({
  params = {},
  enabled = true,
}: UseAccessGroupsParams = {}) => {
  const fetchPage = ({ pageParam = 1 }) =>
    reactQueryGet(
      internalApi.admin.accessGroups.index({
        params: {
          page: pageParam,
          per_page: 100,
          ...params,
        },
      }),
    );

  const { data, fetchNextPage, hasNextPage, isLoading, isError, refetch } =
    useInfiniteQuery([queryKey, params.ids], fetchPage, {
      enabled,
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? Number(lastPage.page) + 1 : undefined,
    });

  useEffect(() => {
    if (hasNextPage) {
      void fetchNextPage();
    }
  }, [data, fetchNextPage, hasNextPage]);

  const allAccessGroups = flatten(data?.pages.map(page => page.records));

  return {
    data: allAccessGroups,
    isError,
    refetch,
    isLoading: Boolean(isLoading || hasNextPage),
  };
};
